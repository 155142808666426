import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import TitleHero from 'components/titleHero';
import Gallery from 'components/gallery';
import { graphql } from 'gatsby';

const Index = ({ data }) => {
  return (
    <>
      <Box>
        <Title as="h2" size="large">
          {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
        </Title>
      </Box>
      <TitleHero size="large" takeover titleData={data.productionsJson}>
        {data.homeJson.heroContent.childMarkdownRemark.html}
      </TitleHero>
      <div style={{ height: '10vh' }} />
      <Box>
        <Title as="h3" size="medium">
          {data.homeJson.contentBlurb.childMarkdownRemark.rawMarkdownBody}
        </Title>
      </Box>
      <Gallery
        items={data.productionsJson.podcasts.slice(1)}
        className="podgallery-front"
      />
    </>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      contentBlurb {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      heroContent {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 500, height: 500) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    productionsJson {
      title
      titleHero
      content {
        childMarkdownRemark {
          html
        }
      }
      podCastHeader
      podcasts {
        title
        slug
        copy
        imageSocial {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 500, height: 500) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        status
        link
        episodes {
          title
          image {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(width: 500, height: 500) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          imageSocial {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(width: 500, height: 500) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          releaseDate
          status
          URI
        }
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 500, height: 500) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
