/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Hero, HeroItem } from './titleHero.css';
import UWAudioPlayer from 'components/UWAudioPlayer/UWAudioPlayerHowler';
import { Link } from 'gatsby';
import find from 'lodash/find';
import Img from 'gatsby-image';

// you don't require a Modal in your project.
export class TitleHero extends PureComponent {
  getTitleHero = titleData => {
    return find(titleData.podcasts, { slug: titleData.titleHero });
  };

  componentDidMount() {
    const { titleData } = this.props;
  }

  render() {
    const {
      children,
      as = 'div',
      size,
      background = 'red',
      takeover = true,
      titleData,
    } = this.props;

    const podcastItem = this.getTitleHero(titleData);
    const { title, copy, link, episodes } = podcastItem;
    const { image } = episodes[0];
    return (
      <>
        <Hero takeover={takeover}>
          <aside>seneste episode</aside>

          <Link
            className="podLink"
            to={link}
            activeStyle={{ color: 'red' }}
            partiallyActive={true}
            title={`læs mere om "${title}" lydfortællingerne`}
          >
            <Img
              className="fluidImage"
              fluid={image ? image.childImageSharp.fluid : {}}
              alt={title}
            />
            <HeroItem
              as={as}
              size={size}
              background={background}
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            />
          </Link>
          <UWAudioPlayer audioList={podcastItem.episodes} takeFirst={true} />
        </Hero>
      </>
    );
  }
}

TitleHero.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  background: PropTypes.string,
  takeover: PropTypes.bool,
  size: PropTypes.oneOf(['large']),
};

export default TitleHero;
