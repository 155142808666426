import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const PlayerWrapper = styled.div`
  z-index: 9999;
  padding: 0 0 5rem;
  -webkit-appearance: none;
  background-color: transparent;

  div.top-container {
    margin-bottom: 40px;
    position: relative;
  }

  div.top-container img.now-playing-album-art {
    width: 200px;
    margin: auto;
    box-shadow: 0 5px 31px 0 rgba(0, 0, 0, 0.5);
    display: none;
  }

  div.control-container {
    margin-top: 40px;
  }

  div.control-container div.amplitude-play-pause {
    cursor: pointer;
    margin: auto;
  }
  div.control-container div.amplitude-play-pause.amplitude-playing {
  }
  div.control-container div.amplitude-play-pause.amplitude-paused {
  }

  div.meta-data-container {
    margin-top: 40px;
  }
  div.meta-data-container span.now-playing-name {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    font-size: 28px;
    font-weight: 700;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div.meta-data-container span.now-playing-artist-album {
    display: block;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
  }

  div.amplitude-wave-form {
    margin-top: 25px;
    margin-bottom: 12px;
  }
  div.amplitude-wave-form svg {
    stroke: #fff;
    height: 50px;
    width: 100%;
    stroke-width: 0.5px;
  }
  div.amplitude-wave-form svg g {
    stroke: #fff;
    height: 50px;
    width: 100%;
  }
  div.amplitude-wave-form svg g path {
    stroke: #fff;
    height: 50px;
    width: 100%;
  }

  span.amplitude-current-time {
    display: block;
    text-align: left;
    color: white;
    font-size: 12px;
  }

  span.amplitude-time-remaining {
    display: block;
    text-align: right;
    color: white;
    font-size: 12px;
  }

  div.song-navigation {
    margin-top: 50px;
    margin-bottom: 25px;
  }
  div.song-navigation input[type='range'] {
    width: 90%;
    -webkit-appearance: none;
    z-index: 9999;
    background-color: inherit;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  div.song-navigation input[type='range']:focus {
    outline: none;
  }
  div.song-navigation input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.25);
  }
  div.song-navigation input[type='range']::-webkit-slider-thumb {
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 0px;
    -webkit-appearance: none;
    margin-top: -10px;
  }
  div.song-navigation input[type='range']::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.25);
  }
  div.song-navigation input[type='range']::-moz-range-thumb {
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 0px;
    -webkit-appearance: none;
    margin-top: -10px;
  }
  div.song-navigation input[type='range']::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.25);
  }
  div.song-navigation input[type='range']::-ms-fill-lower {
    background: transparent;
  }
  div.song-navigation input[type='range']::-ms-fill-upper {
    background: transparent;
  }
  div.song-navigation input[type='range']::-ms-thumb {
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 0px;
    -webkit-appearance: none;
    margin-top: -10px;
  }

  input[type='range']#global-large-song-slider {
    width: 100%;
    margin-top: -74px;
    -webkit-appearance: none;
    z-index: 9999;
    background-color: inherit;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  input[type='range']#global-large-song-slider:focus {
    outline: none;
  }

  input[type='range']#global-large-song-slider::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: 0px;
    height: 68px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
  }

  input[type='range']#global-large-song-slider::-webkit-slider-thumb {
    width: 5px;
    height: 68px;
    background-color: white;
    border-radius: 0px;
    -webkit-appearance: none;
  }

  input[type='range']#global-large-song-slider::-moz-range-track {
    width: 100%;
    height: 0px;
    cursor: pointer;
    border-radius: 0px;
    height: 68px;
    background-color: rgba(0, 0, 0, 0);
  }

  input[type='range']#global-large-song-slider::-moz-range-thumb {
    width: 5px;
    height: 68px;
    background-color: white;
    border-radius: 0px;
    -webkit-appearance: none;
    margin-top: -34px;
  }

  input[type='range']#global-large-song-slider::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.25);
  }

  input[type='range']#global-large-song-slider::-ms-fill-lower {
    background: transparent;
  }

  input[type='range']#global-large-song-slider::-ms-fill-upper {
    background: transparent;
  }

  input[type='range']#global-large-song-slider::-ms-thumb {
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 0px;
    -webkit-appearance: none;
    margin-top: -10px;
  }

  div.time-progress {
    margin-bottom: 30px;
  }
  div.time-progress span.current-time {
    color: #aaafb3;
    font-size: 12px;
    display: block;
    float: left;
    margin-left: 20px;
  }
  div.time-progress div#progress-container {
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    height: 20px;
    cursor: pointer;
    /*
      IE 11
    */
  }

  input[type='range'].amplitude-song-slider {
    -webkit-appearance: none;
    width: 100%;
    margin: -0.25px 0;
  }
  input[type='range'].amplitude-song-slider:focus {
    outline: none;
  }
  input[type='range'].amplitude-song-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 20.5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    background: #484d4d;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type='range'].amplitude-song-slider::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #f9ca24, 0px 0px 0px #fad03d;
    border: 0px solid #f9ca24;
    height: 20px;
    width: 35px;
    border-radius: 0px;
    background: rgba(249, 202, 36, 0.93);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 0.25px;
  }
  input[type='range'].amplitude-song-slider:focus::-webkit-slider-runnable-track {
    background: #949a9a;
  }
  input[type='range'].amplitude-song-slider::-moz-range-track {
    width: 100%;
    height: 20.5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    background: #484d4d;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type='range'].amplitude-song-slider::-moz-range-thumb {
    box-shadow: 0px 0px 0px #f9ca24, 0px 0px 0px #fad03d;
    border: 0px solid #f9ca24;
    height: 20px;
    width: 35px;
    border-radius: 0px;
    background: rgba(249, 202, 36, 0.93);
    cursor: pointer;
  }
  input[type='range'].amplitude-song-slider::-ms-track {
    width: 100%;
    height: 20.5px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range'].amplitude-song-slider::-ms-fill-lower {
    background: #000000;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  }
  input[type='range'].amplitude-song-slider::-ms-fill-upper {
    background: #484d4d;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  }
  input[type='range'].amplitude-song-slider::-ms-thumb {
    box-shadow: 0px 0px 0px #f9ca24, 0px 0px 0px #fad03d;
    border: 0px solid #f9ca24;
    width: 35px;
    border-radius: 0px;
    background: rgba(249, 202, 36, 0.93);
    cursor: pointer;
    height: 20px;
  }
  input[type='range'].amplitude-song-slider:focus::-ms-fill-lower {
    background: #484d4d;
  }
  input[type='range'].amplitude-song-slider:focus::-ms-fill-upper {
    background: #949a9a;
  }

  div.time-progress
    div#progress-container:hover
    input[type='range'].amplitude-song-slider::-webkit-slider-thumb {
    display: block;
  }
  div.time-progress
    div#progress-container:hover
    input[type='range'].amplitude-song-slider::-moz-range-thumb {
    visibility: visible;
  }
  div.time-progress div#progress-container progress#song-played-progress {
    width: 100%;
    position: absolute;
    left: 0;
    top: 8px;
    right: 0;
    width: 100%;
    z-index: 60;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 4px;
    border-radius: 0px;
    background: transparent;
    border: none;
    /* Needed for Firefox */
  }
  @media all and (-ms-high-contrast: none) {
    div.time-progress div#progress-container *::-ms-backdrop,
    div.time-progress div#progress-container progress#song-played-progress {
      color: #fa6733;
      border: none;
      background-color: #e1e1e1;
    }
  }
  @supports (-ms-ime-align: auto) {
    div.time-progress div#progress-container progress#song-played-progress {
      color: #fa6733;
      border: none;
    }
  }
  div.time-progress
    div#progress-container
    progress#song-played-progress[value]::-webkit-progress-bar {
    background: none;
    border-radius: 0px;
  }
  div.time-progress
    div#progress-container
    progress#song-played-progress[value]::-webkit-progress-value {
    background-color: #fa6733;
    border-radius: 0px;
  }
  div.time-progress
    div#progress-container
    progress#song-played-progress::-moz-progress-bar {
    background: none;
    border-radius: 0px;
    background-color: #fa6733;
    height: 5px;
    margin-top: -2px;
  }
  div.time-progress div#progress-container progress#song-buffered-progress {
    position: absolute;
    left: 0;
    top: 8px;
    right: 0;
    width: 100%;
    z-index: 10;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 4px;
    border-radius: 0px;
    background: transparent;
    border: none;
    background-color: #d7dee3;
  }
  div.time-progress
    div#progress-container
    progress#song-buffered-progress[value]::-webkit-progress-bar {
    background-color: #e1e1e1;
    border-radius: 0px;
  }
  div.time-progress
    div#progress-container
    progress#song-buffered-progress[value]::-webkit-progress-value {
    background-color: #e1e1e1;
    border-radius: 0px;
    transition: width 0.1s ease;
  }
  div.time-progress
    div#progress-container
    progress#song-buffered-progress::-moz-progress-bar {
    background: none;
    border-radius: 0px;
    background-color: #e1e1e1;
    height: 5px;
    margin-top: -2px;
  }
  div.time-progress div#progress-container progress::-ms-fill {
    border: none;
  }
  @-moz-document url-prefix() {
    div.time-progress div#progress-container progress#song-buffered-progress {
      top: 9px;
      border: none;
    }
  }
  @media all and (-ms-high-contrast: none) {
    div.time-progress div#progress-container *::-ms-backdrop,
    div.time-progress div#progress-container progress#song-buffered-progress {
      color: #78909c;
      border: none;
    }
  }
  @supports (-ms-ime-align: auto) {
    div.time-progress div#progress-container progress#song-buffered-progress {
      color: #78909c;
      border: none;
    }
  }
  div.time-progress div#progress-container input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    margin: 7.5px 0;
    position: absolute;
    z-index: 9999;
    top: -7px;
    height: 20px;
    cursor: pointer;
    background-color: inherit;
  }
  div.time-progress div#progress-container input[type='range']:focus {
    outline: none;
  }
  div.time-progress
    div#progress-container
    input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 0px;
    cursor: pointer;
    background: #f9ca24;
    border-radius: 0px;
    border: 0;
  }
  div.time-progress
    div#progress-container
    input[type='range']::-webkit-slider-thumb {
    border: 0;
    height: 20px;
    width: 30px;
    border-radius: 0px;
    background: #f9ca24;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10.5px;
  }
  div.time-progress
    div#progress-container
    input[type='range']:focus::-webkit-slider-runnable-track {
    background: #f9ca24;
  }
  div.time-progress
    div#progress-container
    input[type='range']::-moz-range-track {
    width: 100%;
    height: 0px;
    cursor: pointer;
    background: #f9ca24;
    border-radius: 0px;
    border: 0;
  }
  div.time-progress
    div#progress-container
    input[type='range']::-moz-range-thumb {
    border: 0;
    height: 20px;
    width: 30px;
    border-radius: 0px;
    background: #f9ca24;
    cursor: pointer;
  }
  div.time-progress div#progress-container input[type='range']::-ms-track {
    width: 100%;
    height: 0px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  div.time-progress div#progress-container input[type='range']::-ms-fill-lower {
    background: #003d57;
    border: 0px solid #010101;
    border-radius: 0px;
  }
  div.time-progress div#progress-container input[type='range']::-ms-fill-upper {
    background: #f9ca24;
    border: 0;
    border-radius: 0px;
  }
  div.time-progress div#progress-container input[type='range']::-ms-thumb {
    border: 0;
    height: 20px;
    width: 30px;
    border-radius: 0px;
    background: #f9ca24;
    cursor: pointer;
    height: 0px;
    display: none;
  }
  @media all and (-ms-high-contrast: none) {
    div.time-progress div#progress-container *::-ms-backdrop,
    div.time-progress
      div#progress-container
      input[type='range'].amplitude-song-slider {
      padding: 0px;
    }
    div.time-progress div#progress-container *::-ms-backdrop,
    div.time-progress
      div#progress-container
      input[type='range'].amplitude-song-slider::-ms-thumb {
      height: 20px;
      width: 30px;
      border-radius: 10px;
      cursor: pointer;
      margin-top: -8px;
    }
    div.time-progress div#progress-container *::-ms-backdrop,
    div.time-progress
      div#progress-container
      input[type='range'].amplitude-song-slider::-ms-track {
      border-width: 15px 0;
      border-color: transparent;
    }
    div.time-progress div#progress-container *::-ms-backdrop,
    div.time-progress
      div#progress-container
      input[type='range'].amplitude-song-slider::-ms-fill-lower {
      background: #e1e1e1;
      border-radius: 0px;
    }
    div.time-progress div#progress-container *::-ms-backdrop,
    div.time-progress
      div#progress-container
      input[type='range'].amplitude-song-slider::-ms-fill-upper {
      background: #e1e1e1;
      border-radius: 0px;
    }
  }
  @supports (-ms-ime-align: auto) {
    div.time-progress
      div#progress-container
      input[type='range'].amplitude-song-slider::-ms-thumb {
      height: 20px;
      width: 30px;
      margin-top: 3px;
    }
  }
  div.time-progress
    div#progress-container
    input[type='range']:focus::-ms-fill-lower {
    background: #f9ca24;
  }
  div.time-progress
    div#progress-container
    input[type='range']:focus::-ms-fill-upper {
    background: #f9ca24;
  }
  div.time-progress span.duration {
    color: #aaafb3;
    font-size: 12px;
    display: block;
    float: right;
    margin-right: 20px;
  }

  /*
  3. Layout
*/
  div#visualizations-player {
    width: 325px;
    padding: 25px;
    background-color: #482d57;
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &.uw-floats {
    div#visualizations-player {
      width: 100%;
      height: 150px;
      background-color: #fff;
      position: fixed;
      box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
      bottom: 0;
      padding: 0;
      margin: 0;
      color: #000;

      div.time-container {
        top: 15px;
        position: relative;
      }
      div.time-progress div#progress-container progress {
        border-radius: 0;
        height: 20px;
      }

      div.time-progress div#progress-container input[type='range'] {
        margin: 15.5px 0;
      }
    }
    div.top-container {
      height: 150px;
      width: 150px;
      margin: 0;
      img.now-playing-album-art {
        display: block;
        width: 150px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
        img {
          height: 150px;
        }
      }
    }
    .meta-data-container {
      align-self: flex-start;
      display: flex;
      flex-direction: column-reverse;
      order: 1;
      position: absolute;
      left: 180px;
      top: -22px;

      ${MEDIA.DESKTOP`
       
  `};

      ${MEDIA.TABLET`
       
      left: 20px;
  `};

      ${MEDIA.PHONE`
        
      left: 20px;
  `};

      span.now-playing-name {
        font-size: 20px;
        color: #333;
        margin-top: 8px;
        text-align: left;
        ${MEDIA.PHONE`
        
             font-size: 18px;
  `};
      }
      span.now-playing-artist-album {
        text-align: left;
        color: #333;
        font-size: 15px;

        span.now-playing-artist {
          display: none;
        }
      }
    }
    .top-container {
      ${MEDIA.DESKTOP`
       
  `};

      ${MEDIA.TABLET`
        display: none;
  `};

      ${MEDIA.PHONE`
        display: none;
  `};
    }
    .amplitude-time {
      order: 5;
    }
    .time-progress {
      order: 3;
      width: 70%;
      position: relative;
      bottom: -33px;
    }
    .control-container {
      transform: scale(1.2);
      margin-left: 2.5rem;
    }

    .amplitude-wave-form-slider,
    .amplitude-wave-form {
      position: absolute;
      width: 80%;
      margin: 0 0 0 30rem;
    }

    #global-large-song-slider {
      margin-top: 2px !important;
    }

    div.amplitude-wave-form {
      //  height: 10px;
      //  background-color: #333333;
    }

    div.amplitude-wave-form svg {
      //display:none;
    }
  }

  &.uw-static {
    div#visualizations-player {
      flex-direction: row-reverse;
      width: 80vw;
      justify-content: flex-end;
      margin: 0 0 0 2rem;
      padding: 0;
      background: none;
    }
    .meta-data-container,
    .top-container,
    .amplitude-time {
      display: none;
    }

    .control-container {
      margin: 0;
    }

    .amplitude-wave-form-slider,
    .amplitude-wave-form {
      position: absolute;
      width: 50vw;
      margin: 0 0 0 7rem;

      ${MEDIA.DESKTOP`
         width: 65vw;
  `};

      ${MEDIA.TABLET`
         width: 75vw;
  `};

      ${MEDIA.PHONE`
        width: 70vw;
  `};
    }

    #global-large-song-slider {
      margin-top: 2px !important;
    }

    div.amplitude-wave-form svg {
      stroke: #333;
      height: 80px;
      stroke-width: 0.4px;
    }
    div.amplitude-wave-form svg g {
      stroke: #333;
      height: 80px;
    }
    div.amplitude-wave-form svg g path {
      stroke: #333;
      height: 80px;
    }
  }

  &.uw-single {
    div#visualizations-player {
      position: relative;
      max-width: 500px;
      flex-direction: column;
      padding: 0;
      box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
      background-color: #000;
      color: #333333;
      width: 100%;
      margin: 0 auto;
    }
    div.top-container .now-playing-album-art {
      display: block;
      width: 30vh;
      height: 30vh;
      img {
        width: 30vh;
        height: 30vh;
      }
    }
    .meta-data-container {
      margin: 1.5rem;
      span.now-playing-name,
      span.now-playing-artist-album {
        color: #777777;
      }
      span.now-playing-name {
        display: none;
      }
    }
    .top-container {
      margin: 40px;
    }
    .amplitude-time {
    }
    .time-progress {
      width: 90%;
      margin: 0 0 25px 0;
    }
    .control-container {
      transform: scale(1.5);
      color: #fff;
      margin: 0;
      position: absolute;
      top: 15vh;
    }

    #global-large-song-slider {
      margin-top: 2px !important;
    }
  }
`;
