import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Hero = styled.span`
  display: block;
  position: relative;
  margin-top: 6rem;
  width: ${({ takeover }) => () => {
    switch (takeover) {
      case true:
        return '100%';
      default:
        return 'auto';
    }
  }};
  background-color: ${({ takeover }) => () => {
    switch (takeover) {
      case true:
        return '#f9ca24';
      default:
        return 'none';
    }
  }};
  a {
    text-decoration: none;
    &.podLink {
      display: flex;
    }
  }
  .fluidImage {
    width: 25%;
    margin: 4rem;
    display: block;
    ${MEDIA.TABLET`
    display:none;
  `};

    ${MEDIA.DESKTOP`
       display:none;
  `};
  }

  a:hover h2 {
    text-decoration: underline;
  }

  aside {
    color: #fff;
    background-color: black;
    display: block;
    width: 160px;
    line-height: 10px;
    text-transform: capitalize;
    height: 3rem;
    line-height: 3rem;
    margin: 0;
    padding: 0.25rem 4rem;
    font-weight: 700;
    position: absolute;
    top: -1rem;
  }
`;

export const HeroItem = styled.div`
  background: #f9ca24;
  margin: 4rem 0 0;
  padding: 4rem;
  color: #333;

  line-height: 1.2;

  h2 {
    font-size: 4vw;
    font-weight: 800;
    grid-column: col-start / -1;
    grid-row: 1;

    ${MEDIA.DESKTOP`
    font-size: 6vw
  `};

    ${MEDIA.TABLET`
    font-size: 7vw
  `};

    ${MEDIA.PHONE`
    font-size: 10vw
  `};
  }
  p {
    font-size: 3vw;

    ${MEDIA.DESKTOP`
    font-size: 4vw
  `};

    ${MEDIA.TABLET`
    font-size: 5vw
  `};

    ${MEDIA.PHONE`
    font-size: 7vw
  `};
  }
  hr {
    margin: 1.75rem 0 4rem 0;
    border: 5px solid #fff;
    width: 27vw;
  }
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};
  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
  `};
`;
